<template>
  <div>
    <el-dialog :title="isAdd ? '新增':'修改'" :visible.sync="dialogFormVisible" :append-to-body="true" :close-on-click-modal="false" @closed="cancel" width="320px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" v-if="form" :disabled="disabled">
        <el-form-item label="订单金额" prop="limitMoney">
          <price-input v-model="form.limitMoney" style="width: 100%;" />
        </el-form-item>
        <el-form-item label="返利比例" prop="rebateRatio">
          <percent-input v-model="form.rebateRatio" :base="10000" style="width: 100%;" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { add, edit } from "@/api/rebateSet";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      disabled: false,
      form: {
        limitMoney: null,
        rebateRatio: null
      },
      rules: {
        limitMoney: [{ required: true, message: "金额为必填项" }],
        rebateRatio: [{ required: true, message: "比例为必填项" }]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    // 确认保存
    doAdd() {
      add(this.form)
        .then(res => {
          this.dialogFormVisible = false;
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    // 修改
    doEdit() {
      edit(this.form)
        .then(res => {
          this.dialogFormVisible = false;
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialogFormVisible = true;
      this.disabled = false;
      this.form = form || {
        limitMoney: null,
        rebateRatio: null
      };
    }
  }
};
</script>